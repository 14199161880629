import React, { Component } from 'react'
import withStyles, { ThemeProvider } from 'react-jss'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { PREVIEW_TYPES } from '@elo-kit/constants/themes.constants'
import { PRODUCT_TYPE_IDS } from '@elo-kit/constants/products.constants'
import {
  COURSE_DEFAULT_PRODUCT,
  TICKET_DEFAULT_PRODUCT,
  DOWNLOAD_DEFAULT_PRODUCT,
  DEFAULT_PRODUCT,
} from '@elo-kit/constants/contentPage.constants'
import { CUSTOM_CLASS_NAME_OPTION, DEFAULT_PRODUCT_CLASS_NAMES } from '@elo-kit/constants/customCss.constants'
import { PRODUCT_PASSWORD_OPTION_KEY } from '@elo-kit/constants/options.constants'
import { BLOCK_MENU_HIGHLIGHT_IDS } from '@elo-kit/constants/block.constants'

import { isUrlValid } from '@elo-kit/utils/validators.utils'
import { LoadingMask } from '@elo-kit/components/loading-mask'
import { ProductAccess } from '@elo-kit/components/product-access'
import { EloSlider } from '@elo-kit/components/elo-slider'
import { useI18n } from '@elo-kit/components/i18n/i18n'
import { EloWarningCircleIcon } from '@elo-ui/components/icons/regular'
import { EloInfoMessage } from '@elo-ui/components/elo-info-message'

import { getParamsFromUrl } from 'utils/helpers.utils'
import { isWindowEnv } from 'utils/env.utils'
import { formatToMoney } from 'utils/money.utils'
import { patchLink } from 'utils/link.utils'

import productStyles from 'shared/components/content-page/preview/blocks/product/Product.styles'
import MainContent from '../main-content'

/**
 * CoverPresenter - product cover view
 */
export const CoverPresenter = (props) => {
  const { cover, handleLoad, webProtocol, errorTitleMessage } = props
  const { fileName, type, videoLink, url, file = {} } = cover || {}
  const embedStyles = { minWidth: '433px' }
  const videoUrl = patchLink(isUrlValid(videoLink) ? videoLink : `${webProtocol}:${videoLink}`)

  switch (type) {
    case 'video':
      return (
        <div className='embed-responsive embed-responsive-16by9' style={embedStyles}>
          <iframe className='embed-responsive-item' src={videoUrl} frameBorder='0' allowFullScreen title='iframe1' />
        </div>
      )

    case 'image':
      return <img className='img-responsive' alt={fileName} src={file?.custom || url} onLoad={handleLoad} />

    default:
      return <div>{errorTitleMessage}</div>
  }
}

CoverPresenter.displayName = 'CoverPresenter'
CoverPresenter.propTypes = {
  /** Cover object */
  cover: PropTypes.object,
  /** Function which called when image is loaded */
  handleLoad: PropTypes.func,
  /** Default error title message */
  errorTitleMessage: PropTypes.string,
  webProtocol: PropTypes.string,
}
CoverPresenter.defaultProps = {
  cover: {},
  handleLoad: /* istanbul ignore next */ () => {},
}

const productSliderStyles = () => ({
  slider: {
    height: '100%',
    maxWidth: 880,
    position: 'initial',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    textAlign: 'center',

    '& .elo-slider-container > i': {
      fontSize: 40,
      '&:hover': {
        opacity: 0.8,
      },
    },
  },
  sliderContainer: {
    width: '100%',
    '& .img-responsive': {
      width: '430px',
      '@media (max-width: 525px)': {
        width: '380px',
      },
      '@media (max-width: 475px)': {
        width: '350px',
      },
      '@media (max-width: 435px)': {
        width: '280px',
      },
      '@media (max-width: 320px)': {
        width: '240px',
      },
    },
  },
})

/**
 * Covers - product covers list
 */
export const Covers = withStyles(productSliderStyles)((props) => {
  const {
    content = {},
    product,
    classes,
    coversHeights,
    handleLoad,
    previewMode,
    webProtocol,
    errorTitleMessage,
  } = props
  const { slider, sliderContainer } = classes
  const { covers, id } = product
  const firstCover = covers.length && covers[0]

  switch (covers.length) {
    case 0:
      return (
        <div className='no-cover d-flex'>
          <i className='far fa-image m-auto text-white' />
        </div>
      )

    case 1:
      return (
        <div className='item relative' data-id={id} data-product-cover-id={firstCover && firstCover.id}>
          <CoverPresenter cover={firstCover} webProtocol={webProtocol} errorTitleMessage={errorTitleMessage} />
        </div>
      )

    default:
      return (
        <div className={slider}>
          <EloSlider
            previewType={previewMode}
            dynamicHeight
            id='product-covers-slider'
            eloSliderContainerClasses={sliderContainer}
            itemsHeights={coversHeights}
            slideItems={covers.map((cover, index) => (
              <CoverPresenter
                key={cover.id}
                cover={cover}
                handleLoad={(event) => handleLoad(index, event)}
                webProtocol={webProtocol}
                errorTitleMessage={errorTitleMessage}
              />
            ))}
            style={{
              activeElementScale: 1,
              modifier: 1,
              arrowColor: content.arrowsColor,
            }}
          />
        </div>
      )
  }
})

Covers.displayName = 'Covers'
Covers.propTypes = {
  /**
   * Product
   */
  product: PropTypes.object,
  /**
   * List of classes
   */
  classes: PropTypes.object,
  /**
   * List of hieghts of each cover
   */
  coversHeights: PropTypes.object,
  /**
   * Function which called when image is loaded
   */
  handleLoad: PropTypes.func,
  /**
   * Indicates preview mode
   */
  previewMode: PropTypes.bool,
}

/**
 * ProductBlock - main product block component
 */
export class ProductBlock extends Component {
  constructor(props) {
    super(props)

    this.state = {
      coversHeights: {},
      showUnavailableWarning: false,
    }
  }

  componentDidMount() {
    const { seller = {}, product, fetchCourse } = this.props
    const { username } = seller
    const { courseThemeId } = product || {}

    if (courseThemeId && fetchCourse) {
      fetchCourse(courseThemeId, { username })
    }

    const { showUnavailableWarning } = getParamsFromUrl(['showUnavailableWarning'])
    if (!!showUnavailableWarning) {
      this.setState({ showUnavailableWarning: true })
    }
  }

  componentDidUpdate() {
    const { previewMode, toggleLoading } = this.props

    if (previewMode) {
      toggleLoading(false)
    }
  }

  setCoversHeights = (index, event) => {
    const { coversHeights } = this.state
    const { height } = event.target
    this.setState({
      coversHeights: {
        ...coversHeights,
        [index]: height,
      },
    })
  }

  render() {
    const {
      classes,
      getUnit,
      previewType,
      content,
      previewMode,
      historyPush,
      countries,
      getFormattedTicketLocation,
      seller,
      product,
      lessons,
      protectProductByPass,
      productProtectedByPass,
      isAppActive,
      courseItem,
      productLoading,
      getByKey,
      webProtocol,
      errorTitleMessage,
      LinkComponent,
      AudioPlayer,
      location,
      I18n,
      forceFireTracify,
    } = this.props
    const { showProductPage } = getParamsFromUrl(['showProductPage'])

    if (!product) return null

    const { coversHeights } = this.state
    const { covers = [], redirectType, redirectUrl, redirectUrlText } = product

    const coverIsOneImage = product.covers && covers.length === 1 && covers[0].type !== 'video'

    const coverContainerClass = coverIsOneImage ? classes.coverContainer : classes.slideCoverContainer

    const productNetPrice = product.pricingPlans?.[0]?.useNetPrice
    const ticketNetPrice =
      product.form === PRODUCT_TYPE_IDS.eventTickets && product.tickets?.[0]?.pricingPlans?.[0]?.useNetPrice
    const useNetPrice = productNetPrice || ticketNetPrice

    const currencySign = getUnit(product.displayCurrencyId)

    const isExternalRedirect = !!redirectUrl && !!redirectType && redirectType === 'external' && showProductPage
    const isCustomButtonText = isExternalRedirect && !!redirectUrlText
    const buttonText =
      product.free || product.displayPrice == '0.0'
        ? I18n.t('react.shared.button.take_for_free_v2')
        : I18n.t('react.shared.button.go_to_checkout')

    let productToShow

    switch (previewType) {
      case PREVIEW_TYPES.course:
        productToShow = COURSE_DEFAULT_PRODUCT
        break
      case PREVIEW_TYPES.tickets:
        productToShow = TICKET_DEFAULT_PRODUCT
        break
      case PREVIEW_TYPES.download:
        productToShow = DOWNLOAD_DEFAULT_PRODUCT
        break
      default:
        productToShow = DEFAULT_PRODUCT
        break
    }

    const showPasswordProtection = productProtectedByPass && isAppActive(PRODUCT_PASSWORD_OPTION_KEY)

    if (showPasswordProtection) {
      return (
        <div className={classes.productAccessContainer}>
          <ProductAccess setPasswordIsValid={protectProductByPass} password={productProtectedByPass} />
        </div>
      )
    }

    if (!showPasswordProtection) {
      const { is_preview: isPreview } = getByKey() || {}
      const checkoutLink = `/s/${seller.username}/${product.slug}/payment${isPreview ? '?is_preview=true' : ''}`
      const oldPrice = product && product.displayOldPrice

      const handleBuyBtnClick = () => {
        forceFireTracify && forceFireTracify()
        if (isExternalRedirect) {
          return isWindowEnv() && window.open(patchLink(redirectUrl), '_blank', 'noopener')
        }
        return !previewMode ? historyPush(checkoutLink) : null
      }

      return (
        <div
          className={classNames(
            DEFAULT_PRODUCT_CLASS_NAMES.containerClassName,
            content[CUSTOM_CLASS_NAME_OPTION],
            classes.productBlockContainer
          )}
        >
          {productLoading || !product.id ? (
            <LoadingMask dark />
          ) : (
            <div className={classNames('container', classes.productContainer)}>
              <div className='custom-product-container relative'>
                <LinkComponent
                  to={!previewMode ? `/s/${seller.username}` : location?.pathname}
                  className={classNames('back d-block mb-4')}
                  {...(previewMode && {
                    'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.product.backButton,
                    'data-highlighter-selector': '',
                  })}
                >
                  <i className='fas fa-chevron-left mr-2' />
                  {I18n.t('react.shared.button.back')}
                </LinkComponent>

                {this.state.showUnavailableWarning && (
                  <EloInfoMessage icon={<EloWarningCircleIcon />} status='warning'>
                    {I18n.t('react.shared.product_sold_notice')}
                  </EloInfoMessage>
                )}

                <div
                  className={classNames(DEFAULT_PRODUCT_CLASS_NAMES.productNameClassName, classes.productName)}
                  {...(previewMode && {
                    'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.product.productTitle,
                    'data-highlighter-selector': '',
                  })}
                >
                  {product.name}
                </div>

                <div
                  className={classNames(
                    DEFAULT_PRODUCT_CLASS_NAMES.productTopContentClassName,
                    classes.productTopContent
                  )}
                  data-id={product.id}
                >
                  <div className={classNames(DEFAULT_PRODUCT_CLASS_NAMES.productCoverClassName, coverContainerClass)}>
                    <div className='covers'>
                      <Covers
                        coversHeights={coversHeights}
                        handleLoad={this.setCoversHeights}
                        product={product}
                        previewMode={previewMode}
                        webProtocol={webProtocol}
                        errorTitleMessage={errorTitleMessage}
                        content={content}
                      />
                    </div>
                  </div>

                  <div
                    className={classNames(
                      DEFAULT_PRODUCT_CLASS_NAMES.productPriceContainerClassName,
                      classes.priceContainer
                    )}
                  >
                    <div className={classes.priceContent}>
                      {!isExternalRedirect && !product.free && product.displayPrice !== '0.0' && (
                        <>
                          {parseFloat(oldPrice) > 0 && (
                            <div
                              className={classNames(
                                DEFAULT_PRODUCT_CLASS_NAMES.productOldPriceClassName,
                                classes.oldPrice
                              )}
                              {...(previewMode && {
                                'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.product.oldPrice,
                                'data-highlighter-selector': '',
                              })}
                            >
                              <div>
                                <span>{formatToMoney(oldPrice, currencySign)}</span>
                                <hr className={classes.oldPriceLine} />
                              </div>
                              <div />
                            </div>
                          )}
                          <div
                            className={classNames(
                              DEFAULT_PRODUCT_CLASS_NAMES.productPriceClassName,
                              'current-price w-100'
                            )}
                          >
                            <span
                              className={classNames('number')}
                              {...(previewMode && {
                                'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.product.productPrice,
                                'data-highlighter-selector': '',
                              })}
                            >
                              {formatToMoney(product.displayPrice, currencySign)}
                            </span>
                          </div>
                          <div
                            className={classNames('pay-info w-100')}
                            {...(previewMode && {
                              'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.product.paymentInformation,
                              'data-highlighter-selector': '',
                            })}
                          >
                            {useNetPrice ? I18n.t('react.shared.excl_vat') : I18n.t('react.shared.include_vat')}
                          </div>
                        </>
                      )}

                      {isExternalRedirect && <div className='current-price mb-4'>{I18n.t('react.shared.sold')}</div>}

                      <button
                        type='button'
                        className={classNames('btn buy-button')}
                        onClick={handleBuyBtnClick}
                        {...(previewMode && {
                          'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.product.paymentButton,
                          'data-highlighter-selector': '',
                        })}
                      >
                        {isCustomButtonText ? redirectUrlText : buttonText}
                      </button>
                    </div>
                  </div>
                </div>

                <div
                  className={classNames(
                    DEFAULT_PRODUCT_CLASS_NAMES.productBottomClassName,
                    classes.productBottomContent
                  )}
                >
                  <div className={classes.mainContentContainer}>
                    <MainContent
                      AudioPlayer={AudioPlayer}
                      product={previewMode ? productToShow : product}
                      lessons={lessons}
                      username={seller.username}
                      content={content}
                      countries={countries}
                      courseItem={courseItem}
                      isAppActive={isAppActive}
                      getFormattedTicketLocation={getFormattedTicketLocation}
                      locales={{
                        availableTickets: I18n.t('react.shared.available_tickets'),
                        coursePlanTitle: I18n.t('react.shared.course_plan'),
                        downloadTitle: I18n.t('react.shared.download_files_list'),
                        lessonPreviewButtonTitle: I18n.t('react.shared.preview'),
                      }}
                      previewMode={previewMode}
                      hideTickets={isExternalRedirect}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )
    }

    return null
  }
}

ProductBlock.displayName = 'ProductBlock'
ProductBlock.propTypes = {
  /** Product */
  product: PropTypes.object,
  /** List of classes */
  classes: PropTypes.object,
  /** Convert price function received from currency store */
  convertToPrice: PropTypes.func,
  /** Return currency sign from currency key */
  getUnit: PropTypes.func,
  /** Product type for preview */
  previewType: PropTypes.string,
  /** Content object */
  content: PropTypes.object,
  /** Indicates whether it's Page Builder preview of component */
  previewMode: PropTypes.bool,
  /** Browser history push */
  historyPush: PropTypes.func,
  /**  Countries List */
  countries: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  /** Function to format ticket location */
  getFormattedTicketLocation: PropTypes.func,
  /** Seller object */
  seller: PropTypes.object,
  /** Product's lessons */
  lessons: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  /** Function for checking if product password is correct */
  protectProductByPass: PropTypes.func,
  /** Function for checking if product password is correct */
  productProtectedByPass: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /** Function to check if app is activated */
  isAppActive: PropTypes.func,
  /** Product course */
  courseItem: PropTypes.object,
  /** Product is fetching */
  productLoading: PropTypes.bool,
  /** Function to get param from search */
  getByKey: PropTypes.func,
  /** Wrapper link */
  LinkComponent: PropTypes.func,
  /** Default error title message */
  errorTitleMessage: PropTypes.string,
  webProtocol: PropTypes.string,
  /** Fetch course function from courseThemesStore */
  fetchCourse: PropTypes.func,
  /** toggle loading function from contentPageStore */
  toggleLoading: PropTypes.func,
  /** Audio Player */
  AudioPlayer: PropTypes.object,
}

ProductBlock.defaultProps = {
  classes: {},
  previewMode: false,
  LinkComponent: 'a',
  product: {},
  getUnit: /* istanbul ignore next */ () => {},
  convertToPrice: /* istanbul ignore next */ () => {},
  fetchCourse: /* istanbul ignore next */ () => {}, // courseThemesStore.fetchItem,
  toggleLoading: /* istanbul ignore next */ () => {}, // contentPageStore.toggleLoading,
  getByKey: /* istanbul ignore next */ () => {}, // window.Hashovka.getByKey,
  seller: {},
  errorTitleMessage: 'ERROR', // I18n.t('react.shared.error.title')
  webProtocol: 'http',
  AudioPlayer: {
    instances: [],
    init: /* istanbul ignore next */ () => {},
  },
  I18n: {
    t: () => {},
  },
}

const StyledProductBlock = withStyles(productStyles)(ProductBlock)

/**
 * Product - Page Builder's Product Block with react-jss injection
 */
export const Product = (props) => {
  const {
    block: { content },
  } = props
  const I18n = useI18n()

  return (
    <ThemeProvider theme={content}>
      <StyledProductBlock content={content} I18n={I18n} {...props} />
    </ThemeProvider>
  )
}

Product.propTypes = {
  /** Content block object */
  block: PropTypes.shape({
    content: PropTypes.object,
  }),
}

Product.defaultProps = {
  block: {
    content: {},
  },
}

export default Product
