import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { BANNER } from '@elo-kit/constants/contentPage.constants'
import { CUSTOM_CLASS_NAME_OPTION, BANNER_CLASS_NAMES } from '@elo-kit/constants/customCss.constants'
import { BLOCK_MENU_HIGHLIGHT_IDS } from '@elo-kit/constants/block.constants'

import EditTextArea from 'shared/components/page-builder/page-view/edit-text-area/EditTextArea'
import { useI18n } from '@elo-kit/components/i18n/i18n'
import { LazyBackgroundImage } from '@elo-kit/components/lazy-background-image/LazyBackgroundImage'

import { getThemeProps } from '@elo-kit/utils/block.utils'

import { useBannerStyles } from 'shared/components/content-page/preview/blocks/banner/Banner.styles'
import { patchLink } from 'utils/link.utils'

export const BannerContainer = (props) => {
  const {
    classes,
    children,
    emptyPreview,
    title,
    additionalText: blockAdditionalText,
    customClassName,
    defaultAdditionalText,
    defaultTitle,
    previewMode,
    block,
  } = props

  const noImageIconClasses = classNames('far fa-image', classes.noImageIcon)
  const titleClasses = classNames(classes.title)
  const subTitleClasses = classNames(classes.subTitle)
  const mainText = emptyPreview ? defaultTitle : title
  const additionalText = emptyPreview ? defaultAdditionalText : blockAdditionalText

  return (
    <div className={classNames(BANNER_CLASS_NAMES.containerClassName, customClassName, classes.bannerContainer)}>
      <div className={classes.banner}>
        <LazyBackgroundImage block={block} />
        {emptyPreview && <i className={noImageIconClasses} />}
        <div className={classes.bannerInfoContainer}>
          <div className={titleClasses}>
            <EditTextArea
              value={mainText}
              dataKey='titleText'
              previewMode={previewMode}
              highlightDataAttributes={{
                'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.banner.mainText,
                'data-highlighter-selector': '',
              }}
            >
              {mainText}
            </EditTextArea>
          </div>
          <div className={subTitleClasses}>
            <EditTextArea
              value={additionalText}
              dataKey='additionalText'
              previewMode={previewMode}
              highlightDataAttributes={{
                'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.banner.additionalText,
                'data-highlighter-selector': '',
              }}
            >
              {additionalText}
            </EditTextArea>
          </div>
          {children}
        </div>
      </div>
    </div>
  )
}

BannerContainer.propTypes = {
  classes: PropTypes.object,
  customClassName: PropTypes.string,
  emptyPreview: PropTypes.bool,
  defaultTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  defaultAdditionalText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  additionalText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.array]),
  previewMode: PropTypes.bool,
}

BannerContainer.defaultProps = {
  classes: {},
  previewMode: false,
}

export const Button = (props) => {
  const { classes, url, text, animation, previewMode, defaultButtonText } = props

  const buttonClasses = classNames(classes.button, {
    [classes.transparency]: !animation,
    [classes[animation]]: !!animation,
  })

  const handleClick = !previewMode
    ? () => {
        window.location.href = patchLink(url)
      }
    : null

  return (
    <div
      className={classNames(BANNER_CLASS_NAMES.btnContainerClassName, buttonClasses)}
      onClick={handleClick}
      {...(previewMode && {
        'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.banner.customCta,
        'data-highlighter-selector': '',
      })}
    >
      {text || defaultButtonText}
    </div>
  )
}

Button.propTypes = {
  classes: PropTypes.object,
  previewMode: PropTypes.bool,
  animation: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  url: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  defaultButtonText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

Button.defaultProps = {
  previewMode: false,
  classes: {},
}

export const Banner = (props) => {
  const I18n = useI18n()
  const {
    block,
    block: { content },
    previewMode,
    toJS,
    defaultButtonText = I18n.t('react.shared.button.uppercased'),
    defaultTitle = I18n.t('react.shared.here_goes_your_slogan'),
    defaultAdditionalText = I18n.t('react.shared.here_goes_short_description'),
  } = props
  const classes = useBannerStyles({ theme: getThemeProps(block) })
  const emptyPreview = Object.keys(toJS(content)).length < 2
  const showActionButton = !!content[BANNER.showButton] || emptyPreview

  return (
    <BannerContainer
      classes={classes}
      emptyPreview={emptyPreview}
      previewMode={previewMode}
      customClassName={content[CUSTOM_CLASS_NAME_OPTION]}
      title={content[BANNER.title]}
      defaultTitle={defaultTitle}
      additionalText={content[BANNER.additionalText]}
      defaultAdditionalText={defaultAdditionalText}
      block={block}
    >
      {showActionButton && (
        <Button
          classes={classes}
          url={content[BANNER.buttonLink]}
          text={content[BANNER.buttonText]}
          animation={content.buttonAnimation}
          previewMode={previewMode}
          defaultButtonText={defaultButtonText}
        />
      )}
    </BannerContainer>
  )
}

Banner.propTypes = {
  previewMode: PropTypes.bool,
  block: PropTypes.shape({
    content: PropTypes.object,
  }),
  defaultTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  defaultButtonText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  defaultAdditionalText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  toJS: PropTypes.func,
}

Banner.defaultProps = {
  previewMode: false,
  toJS: (obj) => obj,
  block: {
    content: {},
  },
}
