import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'react-jss'
import classNames from 'classnames'
import { get } from 'utils/lodash.utils'

import { useI18n } from '@elo-kit/components/i18n/i18n'

import { isEmpty } from '@elo-kit/utils/validators.utils'
import { getThemeProps } from '@elo-kit/utils/block.utils'
import { getPayerSellerLink, openUrlInNewTab } from '@elo-kit/utils/helpers.utils'
import { LazyBackgroundImage } from '@elo-kit/components/lazy-background-image/LazyBackgroundImage'

import { PRODUCT_TYPE_IDS } from '@elo-kit/constants/products.constants'
import { ELOPAGE_CABINETS, YES, NO } from '@elo-kit/constants/general.constants'
import { BUTTON_TYPE, PAYMENT_OPTION } from '@elo-kit/constants/contentPage.constants'
import { CUSTOM_CLASS_NAME_OPTION, BUTTON_CLASS_NAMES } from '@elo-kit/constants/customCss.constants'
import { BLOCK_MENU_HIGHLIGHT_IDS } from '@elo-kit/constants/block.constants'

import { useButtonStyles } from 'shared/components/content-page/preview/blocks/button/Button.styles'
import { patchLink } from 'utils/link.utils'

import AutoPayModal from '../../components/auto-pay-modal'

import { OrderCompletedModalWithStyles as OrderCompletedModal } from '../popular-products/modals/order-complete-modal'

/** Styled Button */
export const Button = (props) => {
  const {
    content: { buttonAnimation, buttonText },
    onClick,
    previewMode,
  } = props
  const classes = useButtonStyles()
  const buttonClasses = classNames(classes.button, {
    [classes.shadow]: !buttonAnimation,
    [classes[buttonAnimation]]: !!buttonAnimation,
  })
  const I18n = useI18n()

  return (
    <button
      type='button'
      className={buttonClasses}
      onClick={onClick}
      {...(previewMode && {
        'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.button.buttonFunctionality,
        'data-highlighter-selector': '',
      })}
    >
      {buttonText || I18n.t('react.shared.button.buy_now_uppercase')}
    </button>
  )
}

Button.propTypes = {
  /** Block's content */
  content: PropTypes.object,
  /** Block's JSS classes */
  classes: PropTypes.object,
  /** Block's classes */
  onClick: PropTypes.func,
  /** Block id */
  blockId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

Button.defaultProps = {
  content: {},
  classes: {},
}

/** Page-builders button block */
export class ButtonBlock extends Component {
  constructor(props) {
    super(props)

    this.state = {
      product: {},
      autoPayModalProps: {},
      isMounted: false,
    }
  }

  componentDidMount() {
    this.setState({
      isMounted: true,
    })
  }

  componentDidUpdate(prevProps) {
    const { block, previewMode, fetchProduct } = this.props
    const { product = {} } = this.state
    const oldId = prevProps?.block?.content?.productId
    const newId = block?.content?.productId

    if (newId && !previewMode) {
      if (String(oldId) !== String(newId)) {
        fetchProduct(newId)
      }
    } else if (Object.keys(product).length !== 0) {
      // eslint-disable-next-line
      this.setState({
        product: {},
      })
    }
  }

  toggleAutoPayModal = () => {
    const { seller, historyPush } = this.props
    const { autoPayModalProps, product } = this.state
    const { form, id, slug } = product || {}

    if (form === PRODUCT_TYPE_IDS.eventTickets) {
      historyPush(`/s/${seller.username}/${slug}/payment`)
    } else {
      this.setState(() => ({
        autoPayModalProps: {
          active: !autoPayModalProps.active,
          productId: id,
        },
      }))
    }
  }

  toggleOrderCompletedModal = () => {
    const { autoPayModalProps } = this.state

    this.setState(() => ({
      autoPayModalProps: {
        ...autoPayModalProps,
        orderCompletedModal: !autoPayModalProps.orderCompletedModal,
      },
    }))
  }

  fetchButtonProduct = async () => {
    const {
      block: {
        embeddableItem,
        content: { productId },
      },
      fetchProduct,
      previewMode,
    } = this.props

    const { productId: embeddableProductId } = embeddableItem || {}

    if ((productId || embeddableProductId) && !previewMode) {
      const resp = await fetchProduct(productId || embeddableProductId)
      const { data = {} } = resp || {}

      this.setState(() => ({
        product: data,
      }))

      return data
    }
  }

  handleButtonClick = async () => {
    const { block, seller, view, historyPush, params, previewMode, selectLesson, getLinkByParams, ROOT_URL } =
      this.props

    const { username, slug: slugFromUrl } = params || {}
    const { content = {}, goods = [], embeddableItem } = block

    const { buttonType, customLink, paymentOption, lessonId } = content

    const firstGood = goods[0] || {}
    const { file = {} } = firstGood.digital || {}

    const openLinkInNewTab = get(content, 'openLinkInNewTab', '')

    const fetchedProduct = await this.fetchButtonProduct()

    const { product: stateProduct } = this.state
    const product = isEmpty(stateProduct || {}) ? fetchedProduct : stateProduct
    const { slug } = product || {}
    const productLink = `/s/${username}/${slug}`

    const lessonLink = getPayerSellerLink(
      {
        seller,
        slug: `courses/${slugFromUrl}/?lesson_id=${lessonId}`,
      },
      patchLink(ROOT_URL)
    )

    if (!previewMode) {
      switch (buttonType) {
        case BUTTON_TYPE.details: {
          if (slug) {
            if (openLinkInNewTab === YES) {
              openUrlInNewTab(productLink)
            } else {
              ;(() => {
                historyPush(productLink)
                window.scrollTo(0, 0)
              })()
            }
          }
          break
        }

        case BUTTON_TYPE.buy: {
          if (paymentOption === PAYMENT_OPTION.autopay && view === ELOPAGE_CABINETS.payer) {
            this.toggleAutoPayModal()
          } else if (slug) {
            if (openLinkInNewTab === YES) {
              openUrlInNewTab(`${productLink}/payment`)
            } else {
              ;(() => {
                historyPush(`${productLink}/payment`)
                window.scrollTo(0, 0)
              })()
            }
          }
          break
        }

        case BUTTON_TYPE.customLink: {
          if (openLinkInNewTab === NO) {
            window.location.assign(patchLink(customLink))
          } else {
            window.open(patchLink(customLink))
          }
          break
        }

        case BUTTON_TYPE.individualLink: {
          if (product && embeddableItem) {
            if (openLinkInNewTab === NO) {
              window.location.assign(patchLink(getLinkByParams(embeddableItem, product, seller)))
            } else {
              window.open(patchLink(getLinkByParams(embeddableItem, product, seller)))
            }
          }
          break
        }
        case BUTTON_TYPE.lessonLink: {
          if (openLinkInNewTab === YES) {
            window.open(patchLink(lessonLink))
          } else {
            ;(() => {
              selectLesson(lessonId)
              window.scrollTo(0, 0)
            })()
          }
          break
        }
        case BUTTON_TYPE.downloadFile: {
          if (openLinkInNewTab === NO) {
            window.location.assign(file.icon)
          } else {
            window.open(file.icon)
          }
          break
        }
        default:
          break
      }
    }
  }

  render() {
    const {
      classes,
      block,
      block: { content = {}, id: blockId },
      historyPush,
      product,
      sellable,
      fetchProduct,
      sellerItem,
      manageOrder,
      membershipViewType,
      membershipSellable,
      toggleCustomLoading,
      fetchSellable,
      acceptPostsell,
      LinkComponent,
      convertToPrice,
      amountCrossed,
      isWindows,
      getMP3dsBrowserMetaData,
      previewMode,
    } = this.props

    const { autoPayModalProps } = this.state

    return (
      <>
        <div
          className={classNames(
            BUTTON_CLASS_NAMES.containerClassName,
            content[CUSTOM_CLASS_NAME_OPTION],
            classes.container,
            { mounted: this.state.isMounted } // sometimes react do not update classNames on hydrate, Add extra className to trigger react rerender
          )}
        >
          <LazyBackgroundImage block={block} />
          <Button
            content={content}
            onClick={(e) => {
              e.persist()
              this.handleButtonClick(e)
            }}
            blockId={blockId}
            previewMode={previewMode}
          />
        </div>

        {autoPayModalProps.active && (
          <AutoPayModal
            closeModal={this.toggleAutoPayModal}
            productId={autoPayModalProps.productId}
            toggleOrderCompletedModal={this.toggleOrderCompletedModal}
            sellable={sellable}
            fetchProduct={fetchProduct}
            sellerItem={sellerItem}
            manageOrder={manageOrder}
            historyPush={historyPush}
            membershipViewType={membershipViewType}
            membershipSellable={membershipSellable}
            toggleCustomLoading={toggleCustomLoading}
            fetchSellable={fetchSellable}
            product={product}
            acceptPostsell={acceptPostsell}
            classes={classes}
            LinkComponent={LinkComponent}
            convertToPrice={convertToPrice}
            amountCrossed={amountCrossed}
            isWindows={isWindows}
            getMP3dsBrowserMetaData={getMP3dsBrowserMetaData}
          />
        )}

        {autoPayModalProps.orderCompletedModal && (
          <OrderCompletedModal
            closeModal={this.toggleOrderCompletedModal}
            isOpen={autoPayModalProps.orderCompletedModal}
          />
        )}
      </>
    )
  }
}

ButtonBlock.propTypes = {
  /** Root URL */
  ROOT_URL: PropTypes.string,
  /** JSS classes */
  classes: PropTypes.object,
  /** Page Builder's block */
  block: PropTypes.object,
  /** Preview mode flag */
  previewMode: PropTypes.bool,
  /** Push from history / react-router */
  historyPush: PropTypes.func,
  /** Fetch product from contentPageStore */
  fetchProduct: PropTypes.func,
  /** Seller details from contentPageStore */
  seller: PropTypes.object,
  /** View details from contentPageStore */
  view: PropTypes.string,
  /** Match object from react router */
  params: PropTypes.object,
  /** Select lesson handler from courseViewsStore */
  selectLesson: PropTypes.func,
  /** Function to generate links for embeddableItems */
  getLinkByParams: PropTypes.func,
  /** Sellable item */
  sellable: PropTypes.object, // sellablesStore.item
  /** Product id */
  productId: PropTypes.shape([PropTypes.number, PropTypes.string]),
  /** Seller item */
  sellerItem: PropTypes.object, // sellersStore.item
  /** Manage order function */
  manageOrder: PropTypes.func, // shop/api/orders.api
  /** Membership view type received from contentPageStore */
  membershipViewType: PropTypes.string,
  /** Membership sellable id received from contentPageStore */
  membershipSellable: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Function that toggle custom loading */
  toggleCustomLoading: PropTypes.func, // sellablesStore.toggleCustomLoading
  /** Fetch sellable function */
  fetchSellable: PropTypes.func, // sellablesStore.fetchItem
  /** Product item */
  product: PropTypes.object, // productsStore.item
  /** Accept postsell function */
  acceptPostsell: PropTypes.func, // shop/api/funnels.api
  /** Wrapper link for url */
  LinkComponent: PropTypes.func,
  /** Convert price function received from currencyStore */
  convertToPrice: PropTypes.func, // currenciesStore.convertToPrice
  /** Function that cross amount value with style for windows */
  amountCrossed: PropTypes.func, // helpers.utils
  /** Boolean value that say you is windows operating system get from navigator of browser */
  isWindows: PropTypes.bool, // browser.utils
  /** getMP3dsBrowserMetaData received from requests utils */
  getMP3dsBrowserMetaData: PropTypes.func,
}

ButtonBlock.defaultProps = {
  block: {},
  params: {},
  classes: {},
  previewMode: false,
  sellable: {},
  sellerItem: {},
  product: {},
  LinkComponent: 'a',
  convertToPrice: /* istanbul ignore next */ () => {},
  amountCrossed: /* istanbul ignore next */ () => {},
  getMP3dsBrowserMetaData: () => {},
}

/** Styled Button component */
const StyledButtonPreview = (props) => {
  const classes = useButtonStyles()

  return <ButtonBlock {...props} classes={classes} />
}

/** Button component with JSS wrapper */
export const ButtonPreview = (props) => {
  const { block } = props

  return (
    <ThemeProvider theme={getThemeProps(block)}>
      <StyledButtonPreview {...props} />
    </ThemeProvider>
  )
}

ButtonPreview.propTypes = {
  /** Page Builder's block */
  block: PropTypes.object,
  /** Preview mode flag */
  previewMode: PropTypes.bool,
}

ButtonPreview.defaultProps = {
  block: {},
  previewMode: false,
}

export default ButtonPreview
